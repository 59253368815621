import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Title from '../components/Title'
import RichTextRendererFromGatsby from '../components/RichTextRenderer/FromGatsby';
import GoBackIcon from '../components/Icons/GoBackIcon';
import ShareButtons from '../components/ShareButtons';
import { Helmet } from 'react-helmet';
import { addProtocol } from '../utils/format';

const ArticleDetails = ({ data, location }) => {

  const title = data.article?.titre || data.installation?.title.title;
  const rawImageUrl = data?.article?.article?.references[0]?.gatsbyImageData?.images?.fallback?.src
  const image = `https:${rawImageUrl}`;
  // const url = location.href;
  const articleSlug = data?.article?.slug;
  const installationSlug = data?.installation?.slug;
  const articleUrl = `${addProtocol(data.site.siteMetadata.siteUrl, "https")}/actualites/${articleSlug}`;
  const installationUrl = `${addProtocol(data.site.siteMetadata.siteUrl, "https")}/installation/${installationSlug}`;

  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>{title}</title>
        <meta property="og:image" content={image} />
        <meta property="og:image:type" content='image/jpg' />
        <meta property="og:image:alt" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:locale" content='fr' />
        <meta property="og:type" content='article' />
        <meta property="og:site_name" content={data.site.siteMetadata.subtitle} />
        <meta property="og:url" content={articleUrl} />
        <meta name="twitter:card" content='summary' />
        {/* <meta name="twitter:url" content={data.site.siteMetadata.siteUrl} /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:image:alt" content={title} />
      </Helmet>

      <Title title={title} />
      <RichTextRendererFromGatsby details={data.article || data.installation} isArticlesPage={data?.article ? true : false} />
      <ShareButtons
        title={title}
        url={installationSlug ? installationUrl : articleUrl}
      />
      <GoBackIcon />
    </Layout>
  )
}

export default ArticleDetails

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        subtitle
        siteUrl
      }
    }
    article: contentfulArticles(slug: {eq: $slug }) {
      titre
      createdAt(formatString: "D/M/YYYY")
      article {
        raw
        references {
          ...on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(height: 350, width: 450, placeholder: BLURRED)
          }
        }
      }
      slug
    }
    installation: contentfulInstallation(slug: {eq: $slug }) {
      slug
      title {
        title
      }
      createdAt(formatString: "D/M/YYYY")
      content {
        raw
        references {
          ...on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(height: 350, width: 450, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
