import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Box, Typography } from '@material-ui/core';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { addProtocol } from '../../../utils/format';
import styles from '../styles';
import clsx from 'clsx';

const RichTextRendererFromGatsby = ({ details, isArticlesPage, wrapperSize }) => {
  const classes = styles();
  const title = details?.titre || details?.title?.title

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span><strong>{text}</strong></span>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Box my={3}>
          <Typography variant="body1">{children}</Typography>
        </Box>
      ),
      [BLOCKS.HEADING_1]: (node, children) => <Typography variant="subtitle1">{children}</Typography>,
      [BLOCKS.HEADING_2]: (node, children) => <Typography variant="body1">{children}</Typography>,
      [BLOCKS.HEADING_4]: (node, children) => <Typography variant="h2">{children}</Typography>,
      [BLOCKS.HEADING_5]: (node, children) => <Typography variant="subtitle1">{children}</Typography>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => <GatsbyImage image={getImage(node.data.target.gatsbyImageData)} alt={title} className={classes.image} />,
      [INLINES.HYPERLINK]: ({ data }, children) => <a href={addProtocol(data.uri, "https")} target="_blank" rel="noreferrer" className={classes.link}>{children}</a>
    },
  };

  return (
    <div className={clsx(classes[wrapperSize])}>
      {isArticlesPage &&
        <Typography variant="subtitle1" className={clsx(classes.alignEnd, classes.bold)}>Le {details.createdAt}</Typography>
      }
      {renderRichText(details.article || details.content || details, options)}
    </div>
  );

}

export default RichTextRendererFromGatsby

RichTextRendererFromGatsby.propTypes = {
  details: PropTypes.object.isRequired,
  isArticlesPage: PropTypes.bool,
  wrapperSize: PropTypes.oneOf(['largeWrapper', 'smallWrapper'])
};

RichTextRendererFromGatsby.defaultProps = {
  isArticlesPage: false,
  wrapperSize: 'largeWrapper'
};
