import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailIcon,
  EmailShareButton,
  TwitterShareButton,
  TwitterIcon
} from 'react-share';
import styles from './styles';

const ShareButtons = ({ title, url }) => {
  const classes = styles();

  return (
    <Grid container justify="center" className={classes.container}>
      <FacebookShareButton url={url} quote={title} >
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>

      <LinkedinShareButton url={url} >
        <LinkedinIcon size={40} round={true} />
      </LinkedinShareButton>

      <EmailShareButton url={url} title={title}
        subject={title}
        body={`Bonjour, j'ai pensé que cet article pourrait t'intéresser : `}
        openShareDialogOnClick={true}
      >
        <EmailIcon size={40} round={true} />
      </EmailShareButton>
    </Grid>
  )
}

export default ShareButtons;

ShareButtons.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
};

